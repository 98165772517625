<template id='tp_comment'>
  <div class="panel panel-primary ">
    <el-table
        :data="comment_data"
        stripe
        empty-text
        style="width: 100%"
        :show-header="false">
      <el-table-column>
        <template slot-scope="scope">
          <el-row :gutter="20" type="flex" class="row-bg">
            <el-col :span="24">
              <div class="row_user">
                <img class="pan-thumb mr-2" :src="scope.row.userHead">
                <span v-if="scope.row.isShow===1">匿名</span>
                <span v-else>{{scope.row.userName}}</span>
                <i class="el-icon-time" style="margin-right:10px;margin-left: 20px;"></i>{{scope.row.createTime}}
                <span class="pl-5">产品评分：  </span>
                <el-rate
                    disabled
                    v-model="scope.row.productScore"
                    :colors="colors"
                    show-text
                    :texts="['极差', '差', '一般', '满意', '非常满意']"
                ></el-rate>
                <span class="pl-5">服务商评分：  </span>
                <el-rate
                    disabled
                    v-model="scope.row.supportScore"
                    :colors="colors"
                    show-text
                    :texts="['极差', '差', '一般', '满意', '非常满意']"
                ></el-rate>
              </div>

            </el-col>
          </el-row>
          <div class='div_comment_content' v-if="conmentShow===true">
            <span>{{scope.row.comment1}}...</span>
            <el-button style="padding-left: 10px" type="text" @click="conmentShow=false"
                       v-if="scope.row.comment.length>50">展开<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </div>
          <div class="div_comment_content" v-if="conmentShow===false">
            <span>{{scope.row.comment}}</span>
            <el-button style="padding-left: 10px" type="text" v-if="scope.row.comment.length>50"
                       @click="conmentShow=true">收起<i class="el-icon-arrow-up el-icon&#45;&#45;right"></i></el-button>
          </div>
          <div v-if=" serverList.length!==0" >
            <div class="pt-3 pb-5" v-for="item in [serverList]" :key="item.id">
              <div class="div_comment_provider">
                <span class="fwb">服务商回复({{item.createTime}})：</span>
                <div v-if="serverShow===true">
                  <span>{{item.comment1}}...</span>
                  <el-button style="padding-left: 10px" type="text" @click="serverShow=false"
                             v-if="item.comment.length>50">展开<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                </div>
                <div v-if="serverShow===false">
                  <span>{{item.comment}}</span>
                  <el-button style="padding-left: 10px" type="text" v-if="item.comment.length>50"
                             @click="serverShow=true">收起<i class="el-icon-arrow-up el-icon&#45;&#45;right"></i>
                  </el-button>
                </div>
              </div>
            </div>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div class="pt-3">
      <el-pagination
          align="right"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10,20,30,40]"
          :page-size="pageSize"
          layout="total, prev, pager, next,jumper,sizes"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        conmentShow: false,
        serverShow: false,
        total: 0,
        currentPage: 1,
        pageSize: 10,
        input_comment: '',
        comment_data: [],
        serverList: [],
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'],

      }
    },
    props :{
      id: String
    },
    created() {
      this.getCommentList()
    },
    methods: {
      getCommentList() {
        let id = this.id
        this.$http({
          url: this.$http.adornUrl(this.$api.ORDER_COMMENT.ID_LIST),
          methods: 'get',
          params: this.$http.adornParams({
            limit: this.pageSize.toString(),
            page: this.currentPage.toString(),
            serviceId: id
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            let comment_data = data.page.list || []
            comment_data.forEach(item => {
              item.createTime = item.createTime.slice(0, 10)
              if (item.comment.length > 50) {
                this.conmentShow = true
                item.comment1 = item.comment.slice(0, 50)
              }
              if (item.serviceSupportCommentEntity === null) {
                item.serviceSupportCommentEntity = []
                this.serverList = item.serviceSupportCommentEntity
              } else {
                this.serverList = item.serviceSupportCommentEntity || []
                this.serverList.createTime = this.serverList.createTime.slice(0, 10)
                if (this.serverList.comment.length > 50) {
                  this.serverShow = true
                  this.serverList.comment1 = this.serverList.comment.slice(0, 50)
                }
              }
            })
            this.comment_data = comment_data
            this.currentPage = data.page.currPage || 1
            this.pageSize = data.page.pageSize || 10
            this.total = data.page.totalCount || 0
          }
        })
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.getCommentList()
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getCommentList()
      },

    }
  }

</script>

<style scoped>
  [v-cloak] {
    display: none
  }

  .tb_comment {
    width: 100%;
    /* border: 1px solid; */
  }

  .row_user {
    display: inline-flex;
    align-items: center;
    padding-bottom: 15px;
  }

  .tb_comment img {
    width: 64px;
    height: 64px;
  }

  .tb_user {
    width: 80px;
  }


  /* 用户评论内容展示 */
  .div_comment_content {
    padding: 6px 12px;
    border: 1px solid #d2d6de;
    background-color: #fff;
    /*box-shadow: none;*/
  }

  /*#f0f8ff*/
  .div_comment_provider {
    padding: 6px 12px;
    border: 1px solid #d2d6de;
    background-color: #f0f8ff;
  }

  .pan-thumb {
    width: 50px;
    height: 50px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;


  }

  .zc {
    font-size: 15px;
    cursor: pointer;
  }

  .zc:hover {
    color: #05a4ff;
  }

  .imggb {
    margin-right: 5px;
    font-size: 20px;
  }

  .imggb:hover {
    font-size: 25px;
  }
</style>
