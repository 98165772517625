<template>
  <div class="ql-snow wi100" style="color: #000000;">
    <div class="ql-editor" style="padding: 0 !important;">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
  import 'quill/dist/quill.snow.css'
  import '@/assets/css/font.css'
  export default {
    name: 'my-editor-view',
    props: {
      content: {
        required: true,
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped>

</style>